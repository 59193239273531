<template>
  <div id="login">
    <h1>Fantool</h1>
    <form :class="[{ submitted }, 'flex-v']" @submit.prevent="login">
      <label>
        Username
        <input type="text" name="username" required />
      </label>
      <label>
        Password
        <input type="password" name="password" required />
      </label>
      <button
        type="submit"
        class="flex-v"
        :disabled="loading"
        @click="submitted = true"
      >
        <span v-if="loading" class="loading"></span>
        <p>Log in</p>
      </button>
      <div v-if="error" class="error">
        {{
          error.status == 401
            ? 'Username or password is incorrect.'
            : error.message
        }}
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      error: null,
      submitted: false,
      loading: false
    };
  },
  methods: {
    async login(e) {
      this.loading = true;
      const f = e.target;
      try {
        await this.$store.dispatch(`login`, {
          username: f.username.value,
          password: f.password.value
        });
      } catch (e) {
        this.error = e;
      }

      this.submitted = false;
      this.loading = false;
    }
  }
};
</script>

<style>
#login {
  text-align: left;
}

#login * + * {
  margin-top: 1em;
}

#login form {
  font-size: 1.2rem;
}

#login input {
  display: block;
  background: var(--clr-dk-accent);
  border: none;
  border-radius: 5px;
  min-height: 2rem;
  width: 100%;
  color: var(--clr-primary);
  padding: 0.75rem;
  font-size: 1.1rem;
}
#login input:focus {
  box-shadow: var(--clr-lt-accent) 0px 0px 1.5px 1px;
}
#login form.submitted input:invalid {
  box-shadow: #f75c4c 0 0 1.5px 1px;
}

#login button {
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  color: var(--clr-primary);
  cursor: pointer;
  font-weight: bold;
  background: var(--clr-lt-accent);
  font-size: 1.2rem;
  margin-top: 2.5rem;
  position: relative;
  align-items: center;
  justify-content: center;
}

#login button > span {
  position: absolute;
}

#login button > * + p {
  visibility: hidden;
  margin: 0;
}
</style>
